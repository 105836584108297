<template>
  <div>
    <Modal v-model="show" footer-hide>
      <p slot="header" class="text-center">完成上刊</p>
      <div class="m-b-10">
        <div class="p-t-10 title">选择完成上刊的素材并上传</div>
        <Upload class="text-center" :before-upload="handleUpload" multiple action="">
          <div style="padding:20px 0;cursor: pointer;">
            <Icon type="ios-cloud-upload" size="52"></Icon>
            <p>点击或拖拽文件到这里进行上传</p>
          </div>
        </Upload>
      </div>
      <div class="m-b-10">
        <Row v-for="(file, index) in uploadList" :key="'upload_' + index" :gutter="8" class="p-b-10">
          <i-col span="20">{{ file.name }}</i-col>
          <i-col span="4" class="text-right">
            <Icon type="ios-trash-outline" class="delete" style="cursor: pointer;" size="16" @click.native="handleRemove(file)"></Icon>
          </i-col>
        </Row>
      </div>
      <div>
        <Button type="primary" :disabled="uploadList.length===0" :loading="uploadLoading" long @click="handleSubmit">确定并上传</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
export default {
  props: {
    onSuccess: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      show: false,
      taskitemId: null,
      webSubmit: true,
      uploadList: [],
      uploadLoading: false
    }
  },
  methods: {
    showModal (taskitemId = null) {
      if (!taskitemId) {
        this.$Notice.error({ title: '操作失败', desc: '缺少必要参数' })
        return false
      }
      this.taskitemId = taskitemId
      this.show = true
    },
    handleUpload (file) {
      if (this.uploadList.some(f => f.name === file.name)) {
        this.$Notice.warning({ title: '操作提示', desc: `文件名为“${file.name}”已存在上传文件列表。` })
        return false
      }
      this.uploadList.push(file)
      return false
    },
    handleRemove (file) {
      this.uploadList.splice(this.uploadList.indexOf(file), 1)
    },
    handleSubmit () {
      if (this.uploadList.length === 0) {
        this.$Notice.error({ title: '操作提示', desc: '请选择需要上传的素材文件。' })
        return false
      }
      const param = new FormData()
      param.append('taskitemId', this.taskitemId)
      param.append('webSubmit', this.webSubmit)
      this.uploadList.forEach(file => {
        param.append('file', file)
      })
      this.uploadLoading = true
      axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v3/apptask/finishtask',
        param,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true
          // onUploadProgress (progress) { // 处理上传文件进度条数据
          //   _this.percentage = Math.round(progress.loaded / progress.total * 100) >= 95 ? 95 : Math.round(progress.loaded / progress.total * 100)
          // }
        }
      ).then(res => {
        this.uploadLoading = false
        if (res && res.data && res.data.errcode === 0) {
          this.uploadList = []
          this.$Notice.success({ desc: '操作成功' })
          this.onSuccess()
          this.show = false
        }
      })
    }
  }
}
</script>
